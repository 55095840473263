.cardsDescription{
    color: var(--darkGreyColor);
    font-size: .8rem;
    line-height: 1.2rem;
    margin-bottom: .5rem;
}

.cardsPrice{
    color: var(--darkGreyColor);
    font-size: .9rem;
    line-height: 1.2rem;
    margin-bottom: 0;
    padding-bottom: 0;
}
.price{
    color: var(--maroonColor);
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 0;
}

.container {
    background-color: var(--darkGreyColor);
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative!important; /* If you want text inside of it */
    border-radius: 5px;
  -webkit-border-radius:5px;
  -moz-border-radius:5px;
  margin-bottom:1rem;
  display: block;

  }
  
  /* If you want text inside of the container */
  .text {
    position: absolute!important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
